import React, { useState } from 'react'
import banner from "../img/Logo.png";
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function ForgotPassword() {

  const [email, setEmail] = useState("");
  const [showEmail, setShowEmail] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handlePassword1Change = (event) => {
    setPassword1(event.target.value);
  }


  const handlePassword2Change = (event) => {
    setPassword2(event.target.value);


  }


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (showEmail === true) {
      setShowEmail(false);
      setShowPassword(true);
    }  else {
      console.log()
    }
  };

  const handleSavePassword = async(event) => {
  }

  return (
    <div className='container'>
      <div className="row" style={{alignItems: 'center'}}>
          <div className='col-md-6 col-sm-12'>
            <img src={banner} alt='banner' style={{ height: '700px', width: '700px'}}/>
          </div>
          <div className='col-md-6 col-sm-12' >
              
              {
                showEmail && 
                  <>
                  <form className="login" onSubmit={handleSubmit}>
                    <h2>Forgot Password</h2>
                    <p>Please enter the Email Id</p>
                    <input type="text" placeholder="Email" value={email} onChange={handleEmailChange} required />
                    <div>
                      <button type="submit" className='submitButton' defaultValue="Log In">Next</button>
                    </div>
                  </form>
                  </>
              }
              {
                showPassword &&
                <>
                  <form className="login" onSubmit={handleSavePassword}>
                      <h2>Forgot Password</h2>
                      <p>Reset Password</p>
                      <label>New Password</label>
                      <input type="password" placeholder="Enter New Password" value={password1} onChange={handlePassword1Change} required />
                      <label>Confirm New Password</label>
                      <input type="password" placeholder="Confirm New Password" value={password2} onChange={handlePassword2Change} required />
                      <div>
                        <button type="submit" className='submitButton' defaultValue="Log In">Submit</button>
                      </div>
                  </form>
                </>
              }
          </div>
      </div>
    </div>
  )
}
