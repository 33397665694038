// App.js

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Navbar from '../component/Navbar';
import './Outsource.css';

const Container = styled.div`
  padding: 20px;
  text-align: center;
`; 

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    align-items: flex-start;
  }
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 8px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const DataDisplay = styled.div`
  border: 1px solid #ccc;
  padding: 15px;
  text-align: center;
`;

const Dropdown = styled.select`
  padding: 8px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

function Outsourcepage() {
  const [orders, setOrders] = useState([]);
  const [status, setstatus] = useState("Registration");
  const [lab, setLab] = useState('lab05');
  const sendsample = async (id) => {
    try {
      const response = await axios.post('https://apiv2.labridge.in/bookTestB2b/sample/sent', { orderitemsid: id}); // Adjust the URL and status as per your requirement
     if(response.data.success){
      fetchOrders(status,lab);
     }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };
  const reportsent = async (id) => {
    try {
      const response = await axios.post('https://apiv2.labridge.in/bookTestB2b/sample/processed', { orderitemsid: id}); // Adjust the URL and status as per your requirement
     if(response.data.success){
      fetchOrders(status,lab);
     }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };
  const fetchOrders = async (statusin, labin) => {
    try {
      const response = await axios.post('https://apiv2.labridge.in/bookTestB2b/ordersstatus', { status: statusin , lab:labin}); // Adjust the URL and status as per your requirement
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };
  useEffect(() => {
  

    fetchOrders(status,lab);
  }, []);

  return (
    <div className="wrapper">
       <Navbar/>
       <div id="content"> 
        <div></div>
    <div className="container">
      <h1 className="text-center mt-4 mb-5">Outsource Booking</h1>
      <FormGroup>
          <Label htmlFor="lab">Lab</Label>
          <Dropdown
            id="lab"
            value={lab}
            onChange={(e) => {setLab(e.target.value);fetchOrders(status,e.target.value);}}
          >
            <option value="lab05">BRL</option>
            <option value="lab06">zipatho</option>
            <option value="lab08">Nalasopara</option>
            <option value="lab09">Akola</option>
          </Dropdown>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="lab">Status</Label>
          <Dropdown
            id="lab"
            value={status}
            onChange={(e) => {setstatus(e.target.value);fetchOrders(e.target.value,lab);}}
          >
            <option value="Registration">Registration</option>
            <option value="Sample Sent">Sent Sample</option>
            
          </Dropdown>
        </FormGroup>
      <div className="row mt-5">
        {orders.length >0 ? orders.map((order, index) => (
          <div key={index} className="col-lg-4 col-md-6 mb-4">
            <div className="card p-2">
              <div className="card-body">
                <h5 className="card-title">{order.patientName}</h5>
                <p className="card-text">Lab/HPL: {order.labhpl}</p>
                <p className="card-text">Date Ordered: {new Date(order.dateOrdered).toLocaleString()}</p>
                <div className="sample-info">
                 
                      <p className="mb-0">Test:<b> {order.testName}</b></p>
                      <p className="mb-0">Sample:<b> {order.sampleType}</b></p>
                      <p className="mb-0">Barcode:<b> {order.barcode}</b></p>
                      <p className="mb-0">Destination:<b> {order.outSourcedestination}</b></p>
                      <p className="mb-0">Contact Person:<b> {order.contactperson}</b></p>
                      <p className="mb-0">Mobile:<b> {order.mobile}</b></p>
                      <p className="mb-0">Address:<b> {order.address}</b></p>
                    </div>
                 
                </div>
               {status=="Registration" ? <button className='btn btn-success' onClick={()=>{sendsample(order.orderitemid)}}>Send Sample</button> : <button className='btn btn-warning' onClick={()=>{reportsent(order.orderitemid)}}>Report Received</button> }
              </div>
             
            </div>
         
        )):<p>No Outsource Booking</p>}
      </div>
    </div>
    </div>
    </div>
    
  );
}

export default Outsourcepage;

