import { Input } from '@mui/material';
import Autocomplete from "@mui/material/Autocomplete";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from "@mui/material/TextField";
import axios from 'axios';
import copy from "copy-to-clipboard";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import wrongIcon from "../img/cancel.svg";
import copyIcon from "../img/copy.svg";
import correctIcon from "../img/correct.svg";
import viewIcon from "../img/view.svg";
import Navbar from './Navbar';




function ClientRegister() {

  const navigate = useNavigate();
const [pendingdata, setpendingadata] = useState([]);
const [adhar, setadhar] = useState("");
const [pan, setpan] = useState("");
const [Certificate, setcertificate] = useState("");
const [banner, setbanner] = useState("");
const [ids, setids] = useState("");
const [name, setname] = useState("");
const [email, setemail] = useState("");
const [comment, setcomment] = useState("");
const [reqdata, setreqdata] = useState([]);
const [ratelist, setratelist] = useState([]);
const [labs, setlabs] = useState([]);
const [labids, setlabids] = useState([]);
const [labcode, setlabcode] = useState("");
const [percent, setpercent] = useState(0);
const [labfor, setlabfor] = useState("");
const [selectedtest, setselectedtest] = useState([]);


   function getpendingdata(){
         axios.get('https://apiv2.labridge.in/apiforsubmission/pendinglab').then((data)=>{
            //setpendingadata(data.data);
            setreqdata(data.data.data);
         })
    }
  //   const readUploadFile = (e) => {
  //     e.preventDefault();
  //     if (e.target.files) {
  //         const reader = new FileReader();
  //         reader.onload = (e) => {
  //             const data = e.target.result;
  //             const workbook = XLSX.read(data, { type: "array" });
  //             const sheetName = workbook.SheetNames[0];
  //             const worksheet = workbook.Sheets[sheetName];
  //             const json = XLSX.utils.sheet_to_json(worksheet);
  //             setratelist(json);
  //         };
  //         reader.readAsArrayBuffer(e.target.files[0]);
  //     }
  // }

function getlabs(){
  axios.get('https://apiv2.labridge.in/apiforsubmission/labs').then((data)=>{
    //setpendingadata(data.data);
    setlabs(data.data.result);
 })
}

const copyToClipboard = (copyText) => {
  copy(copyText);
  toast.success("Id has been copied");
}



  function approve(){

    axios.post('https://apiv2.labridge.in/admin/action',{
      adminId:"64258c623b87c5c8d387b83e",
      partnerId: ids,
      action:"Application Approved",
      comment:comment
    }).then((data)=>{
      if(data.data.success===true){
      axios.post('https://apiv2.labridge.in/apiforsubmission/approve2',{
        lab:labcode,
        partnerId:ids,
        labids:labids,
        percentage:percent,
        selectedtest:selectedtest,
        password:`${Date.now()}`
      }).then((data)=>{
        window.location.reload();
        getpendingdata();
      })
}  else{
alert("Something Went Wrong!!")
}    
})


  }

    function reject(){
      axios.post('https://apiv2.labridge.in/admin/action',{
        adminId:"64258c623b87c5c8d387b83e",
        partnerId: ids,
        action:"Application Rejected",
        comment:comment
      }).then((data)=>{
        if(data.data.success===true){
        axios.post('https://apiv2.labridge.in/apiforsubmission/reject',{
          id:ids,
          labName:name,
          email:email
        }).then((data)=>{
          window.location.reload();
          getpendingdata();
        })
}  else{
  alert("Something Went Wrong!!")
}    
})

    
    }
    
    useEffect(() => {
      const isLoggedIn = localStorage.getItem('loginData');
      
    
      // If not logged in, redirect to the login page
      if (!isLoggedIn) {
        navigate('/login');
      }
    }, []);
useEffect(()=>{
  getlabs();
    getpendingdata();
},[])
  return (
    <div>
      <div className="wrapper">
        {/* Sidebar  */}
        <Navbar/>
        
        {/* Page Content  */}
        <div id="content">  
          <div>
            <h2>Pending Request</h2>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell><b>Client Name</b></TableCell>
                      <TableCell align="left"><b>Email</b></TableCell>
                      <TableCell align="left"><b>Mobile</b></TableCell>
                      <TableCell align="left"><b>City</b></TableCell>
                      <TableCell align="left"><b>State</b></TableCell>
                      <TableCell align="left"><b>Pincode</b></TableCell>
                      {/* <TableCell align="left"><b>Documents</b></TableCell> */}
                      <TableCell align="left"><b>Action</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reqdata.map((data)=> {
                      return <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                {data.labName}<img src={copyIcon} style={{width: '20px', marginLeft: '10px'}} onClick={()=>{copyToClipboard(data.id)}} alt=''/>
                                </TableCell>
                                <TableCell align="left">{data.email}</TableCell>
                                <TableCell align="left">{data.mobile}</TableCell>
                                <TableCell align="left">{data.city}</TableCell>
                                <TableCell align="left">{data.state}</TableCell>
                                <TableCell align="left">{data.pinCode}</TableCell>
                                <TableCell align="left">
                                  <img alt='' src={viewIcon} onClick={()=>{
                                      setadhar(data.adharcard);
                                      setcertificate(data.certificate);
                                      setpan(data.pancard);
                                      setbanner(data.logo);
                                     
                                    }} style={{width: '25px'}} 
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                    />
                                  <img 
                                    alt=''
                                    src={correctIcon}
                                    onClick={()=>{
                                      setids(data._id);
                                      setlabfor(data.processinglab)
                                    }}
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter1"
                                    style={{width: '25px'}}
                                  />
                                  <img 
                                    alt=''
                                    src={wrongIcon}
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter2"
                                    onClick={()=>{
                                      setids(data._id);
                                      setname(data.labName);
                                      setemail(data.email);
                                    }}
                                    style={{width: '25px'}}
                                  />
                                </TableCell>

                              </TableRow>
                            })}

                  </TableBody>
                </Table>
              </TableContainer>
              <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content d-flex justify-content-center align-items-center">
                   
                    <div className="modal-header">
                      Choose File 
                    </div>
                    <div className="modal-body ">
                      <a className='btn btn-secondary me-2' href={adhar} target='_blank'>AdharCard</a>
                      <a className='btn btn-secondary me-2' href={pan} target='_blank'>Pancard</a>
                      <a className='btn btn-secondary me-2' href={Certificate} target='_blank'>Certificate</a>
                      <a className='btn btn-secondary me-2' href={banner} target='_blank'>Banner</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal fade" id="exampleModalCenter1" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content d-flex justify-content-center align-items-center"> 
                    <div className="modal-header">
                      Select Lab ({labfor})
                    </div>
                    <div className="modal-body ">
  
    <Autocomplete
      multiple
      onChange={(event, value) => {
        var labidss = [];
        for (let i = 0; i < value.length; i++) {
          labidss.push(value[i]._id);
        }
        setlabids(labidss);
        setlabcode(value[0].labCode);
      }}
      id="tags-outlined"
      options={labs}
      required
      getOptionLabel={(option) => option.labName}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Lab"
          placeholder="Favorites"
        />
      )}
    />
 
 
   
    <Input className='mt-2' type="number" placeholder='% increase' onChange={(e)=>{setpercent(e.target.value)}}/>
  
</div>
                    <button className='btn btn-success mt-2 mb-2' onClick={()=>{approve()}} >Submit</button>
                  </div>
                </div>
              </div>
              <div className="modal fade" id="exampleModalCenter2" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content d-flex justify-content-center align-items-center">
                    <div className="modal-header">
                      Reason for rejection.
                    </div>
                    <div className="modal-body ">
                      <input type='text' onChange={(e)=>{setcomment(e.target.value)}} />
                      <button className='btn btn-danger  ms-2' onClick={()=>{reject()}}>Submit</button>
                    </div>
                  </div>
                </div>
              </div>
              <ToastContainer />
            </div>
          </div>
      </div>
  </div>
    
  )
}

export default ClientRegister