import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Navbar from '../component/Navbar';
import SearchTest from '../component/Searchtest';
import Serchclient from '../component/Serchclient';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 800px;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Dropdown = styled.select`
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Input = styled.input`
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const SuccessMessage = styled.div`
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: #28a745;
  color: white;
  font-size: 16px;
`;

function Addtesttoclient() {
  const navigate = useNavigate();
  const [selectedLab, setSelectedLab] = useState(null);
  const [selectedTest, setSelectedTest] = useState([]);
  const [labid, setlabid] = useState("");
  const [testPrice, setTestPrice] = useState();
  const [tat, setTat] = useState();
  const [mrp, setmrp] = useState();
  const [lab, setLab] = useState('648ffe442be36ad5f1acb4ec'); // Default lab value
  const [successMessage, setSuccessMessage] = useState('');

  const handleSelectLab = (lab) => {
    setSelectedLab({
      _id: lab._id,
      name: lab.labName,
      agent: lab.agent,
    });
  };

  const handleSelectLab1 = (lab) => {
    setlabid(lab._id);
    setTestPrice(lab.testPrice);
    setTat(lab.tat);
    setmrp(lab.mrp);
    setSelectedTest(lab);
  };

  const updateLabTestDetails = async () => {
    try {
      const response = await axios.put(`https://apiv2.labridge.in/admin/api/labtests/${labid}`, {
        testPrice: parseInt(testPrice),
        mrp: mrp,
        tat: tat,
      });
      console.log('Updated Lab Test:', response.data);
      setSuccessMessage('Lab test details updated successfully!');
      setTimeout(() => setSuccessMessage(''), 3000); // Clear message after 3 seconds
    } catch (error) {
      console.error('Error updating lab test details:', error);
    }
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('loginData');

    // If not logged in, redirect to the login page
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [navigate]);

  return (
    <div className="wrapper">
      {/* Sidebar */}
      <Navbar />

      {/* Page Content */}
      <div id="content">
        <Container>
          <Title>Change Test Details Clientwise</Title>
          <FormContainer>
            <FormGroup>
              <Label htmlFor="lab">Select Lab</Label>
              <Dropdown
                id="lab"
                value={lab}
                onChange={(e) => setLab(e.target.value)}
              >
                <option value="648ffe442be36ad5f1acb4ec">Thane</option>
                <option value="6585432d4f8639a1d3a6bd02">Nsp</option>
                <option value="65266c8a27e4b9d512e0d54d">Zipatho</option>
                <option value="65c1157532f8a73e726702d1">Akola</option>
                <option value="66f2b5767dad85c8462efa76">Pune</option>
              </Dropdown>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="lab2">Client</Label>
              <Serchclient id="lab2" onSelectLab={handleSelectLab} slabid={lab} />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="lab1">Test</Label>
              <SearchTest id="lab1" onSelectLab={handleSelectLab1} labcode={selectedLab} />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="testPrice">B2B</Label>
              <Input
                type="text"
                id="testPrice"
                value={testPrice}
                className="form-control"
                onChange={(e) => setTestPrice(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="mrp">MRP</Label>
              <Input
                type="text"
                id="mrp"
                value={mrp}
                className="form-control"
                onChange={(e) => setmrp(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="tat">TAT</Label>
              <Input
                type="text"
                id="tat"
                value={tat}
                className="form-control"
                onChange={(e) => setTat(e.target.value)}
              />
            </FormGroup>
            <Button onClick={updateLabTestDetails}>Update</Button>
            {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
          </FormContainer>
        </Container>
      </div>
    </div>
  );
}

export default Addtesttoclient;
