import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
function Login() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useNavigate();
  
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = {
      email: email,
      password: password,
    }
    const result =  await axios.post(`https://apiv2.labridge.in/admin/login`, body);
    if (result.data.responseCode == 200 ){

      localStorage.setItem("loginData", JSON.stringify(result.data.data));
      if(result.data.data.access.length == 1 && result.data.data.access.includes("4") ){
        history('/assigncredits');
      }
      else if(result.data.data.access.length == 1 && result.data.data.access.includes("6")){
      history('/outsource/registration');}
      else{
        history('/');
      }
    }
    else{
      alert(result.data.data);
    }
  };

  return (
    <div className='container'>
      <div className="row" style={{alignItems: 'center'}}>
        <div className='d-flex justify-content-center align-items-center' style={{width:"100%", height:"100vh"}}>
          <div style={{width:"100%"}} >
            <form className="login" onSubmit={handleSubmit}>
              <h2>Welcome, User!</h2>
              <p>Please log in</p>
              <input type="text" placeholder="Email" value={email} onChange={handleEmailChange} required/>
              <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} required/>
              <div>
                 <button type="submit" className='submitButton' defaultValue="Log In" >LogIn</button>
                 <Link to="/forgot-password" style={{color: "#000000", textDecoration: 'underLine'}}>Forgot password</Link>
              </div>
            </form>
          </div>
          </div>
      </div>
    </div>
  )
}

export default Login