import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Navbar() {
  const adminData = JSON.parse(localStorage.getItem('loginData'));
  const history = useNavigate();

  function handleLogout() {
    localStorage.removeItem("loginData");
    history("/login");
  }

  if (!adminData || !adminData.access) {
    return null; // or render a loading indicator or redirect to login
  }

  const access = adminData.access;

  return (
    <nav id="sidebar">
      <div className="sidebar-header">
        <h3>Labridge</h3>
      </div>
      <ul className="list-unstyled components">
        <p>Admin Dashboard</p>

        <li>
          <a
            href="#pageSubmenu"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            Client Management
          </a>
          <ul className="collapse list-unstyled" id="pageSubmenu">
            {access.includes("1") && (
              <li>
                <Link to="/onboardclient">Client On-Boarding</Link>
              </li>
            )}
            {access.includes("2") && (
              <li>
                <Link to="/bookingdetail">Change Booking Details</Link>
              </li>
            )}
            {access.includes("4") && (
              <li>
                <a href="/assigncredits">Assign Credit / Refund</a>
              </li>
            )}
            {access.includes("9") && (
            <li>
                <Link to="/testdetails">Test Price Clientwise</Link>
              </li> )}
              {/* <li>
                <Link to="/testdetailslab">Test Price Labwise</Link>
              </li>
              <li>
                <Link to="/assigntest">Assign Test</Link>
              </li> */}
          </ul>
        </li>
        <li>
          <a
            href="#pageSubmenu3"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            Lab Internal OPS
          </a>
          <ul className="collapse list-unstyled" id="pageSubmenu3">
            
              {/* <li>
                <Link to="/onboardclient">Barcode Search</Link>
              </li> */}
            
            {access.includes("6") && (
              <li>
                <Link to="/outsource/registration">Outsource Booking</Link>
              </li>
            )}
            {access.includes("7") && (
              <li>
                <a href="/test-data/registration">Upcoming Tests</a>
              </li>
            )}
          </ul>
        </li>
        {access.includes("5") && (
          <li>
            <a
              href="#pageSubmenu2"
              data-toggle="collapse"
              aria-expanded="false"
              className="dropdown-toggle"
            >
              Mis Data
            </a>
            <ul className="collapse list-unstyled" id="pageSubmenu2">
              <li>
                <Link to='/total-booking'>Total Booking Details</Link>
              </li>
              <li>
                <Link to='/test-data'>Test Wise Details</Link>
              </li>
              <li>
                <Link to="/test-data-clientwise">Client Ledger</Link>
              </li>
              <li>
                <Link to="/onboareding-data">On-boarding Details</Link>
              </li>
            </ul>
          </li>
        )}
      </ul>
      <center>
        <button className='btn btn-logout ' onClick={handleLogout}>Logout</button>
      </center>
    </nav>
  );
}

export default Navbar;
