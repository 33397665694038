
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../component/Navbar';
const Bookingdetails = () => {
  const userdetails = JSON.parse(localStorage.getItem('loginData'));
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [patientData, setPatientData] = useState(null);
  const [editedPatientName, setEditedPatientName] = useState('');
  const [editedGender, setEditedGender] = useState('');
  const [editedDoctorName, setEditedDoctorName] = useState('');
  const [editedTitle, setEditedTitle] = useState('');
 const [disable, setdisable] = useState(false);
  const [years, setYears] = useState(0);
  const [months, setMonths] = useState(0);
  const [days, setDays] = useState(0);
  const possibleNameTitles = [
    'Mr.',
    'Mrs.',
    'Miss',
    'Master',
    'Dr.',
    'Prof.',
    'Baby.',
    'Daughter of',
    'Son of'
  ];
  const handleSearch = async () => {
    try {
      const response = await axios.get(`https://apiv2.labridge.in/admin/search/${searchTerm}`);
      setPatientData(response.data);
      setEditedPatientName(response.data.patientName);
      setEditedGender(response.data.gender);
      setEditedDoctorName(response.data.doctorName);
      setEditedTitle(response.data.title);

    } catch (error) {
      console.error('Error fetching patient data:', error);
    }
  };
const cancelbooking = async () =>{
    try {
        const response = await axios.get(`https://apiv2.labridge.in/admin/Canceltest/${patientData._id}`);
        if(response.data.success){
            alert('Test Cancelled');
        }
    } catch (error) {
        
    }
}
const logAdminAction = async (action, details) => {
  try {
    const adminData = JSON.parse(localStorage.getItem('loginData'));
    await fetch('https://apiv2.labridge.in/admin/logadminaction', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        adminId: adminData.adminId, // Admin performing the action
        action,  // The type of action being logged
        details, // Additional relevant data for that action
      }),
    });
  } catch (error) {
    console.error('Error logging admin action:', error);
  }
};
async function stauscheck(){
  const reqBody = {
    "ExternalRegnPDF": {
        "ExternalPatientId": patientData.integrationBookingId,
        "LabID": "f6475862-63d7-4a39-aa03-2e9fbea7e07f"
    }
} 
  const result = await axios.post('https://corpmaintest.elabassist.com/Services/MachineService.svc/ExternalRegistrationSynchForLab_PDF', reqBody);

  if(result.data.d[0].Status == "Success"){
    if(result.data.d[0].CurrentState == "Registration"){
      finalupdate();
    }
    else{
      alert("Unable to edit now. Accession Done");
    }
  }
}

async function finalupdate(){
    setdisable(true);
    const result = await axios.post('https://apiv2.labridge.in/admin/updatebookingdetails',{
        name: editedPatientName,
        gender: editedGender,
        day:days,
        month:months,
        year:years,
        docter:editedDoctorName,
        title:editedTitle,
        integraionid:patientData.integrationBookingId,
        RefByID:patientData.user.refbyId,
        entityid:patientData.user.labids[0].entityid,
        id:patientData._id
    });
   if(result.data.status){
    alert("Order Updated");
    setdisable(false);
   }
   else{
    alert("Something went wrong");
    setdisable(false);
   } 
}

  const handleFocus = (setter) => () => {
    if (setter() === 0) {
      setter(null);
    }
  };
  
  const handleBlur = (setter) => (e) => {
    const value = parseInt(e.target.value);
    if (isNaN(value)) {
      setter(0);
    } else {
      setter(value);
    }
  };
  useEffect(() => {
    if (searchTerm.trim() !== '') {
      handleSearch();
    } else {
      setPatientData(null);
    }
  }, [searchTerm]);
  useEffect(() => {
    const isLoggedIn = localStorage.getItem('loginData');
    
  
    // If not logged in, redirect to the login page
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, []);
  return (
    
    <div className="wrapper">
        <Navbar />
    <div className="container mt-5" id='content'>
      <h1 className="mb-4">Booking Details</h1>
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Enter Order Id"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {patientData && (
        <div className="card">
          <div className="card-body">
            <h2 className="card-title">Patient Information</h2>

            <div className="mb-3">
              <label className="form-label">Patient Name:</label>
              <input type="text" className="form-control" value={editedPatientName} onChange={(e)=>{setEditedPatientName(e.target.value)}} />
            </div>

            <div className="mb-3">
            <div className="form-outline">
    <label className="form-label" htmlFor="ageInput">
      Age(current age - {patientData.age}) 
    </label>
    <div className="d-flex">
      <div className="input-group me-2">
        <span className="input-group-text">Y</span>
        <input
          type="number"
          id="yearsInput"
          className="form-control"
          
          onFocus={handleFocus(setYears)}
          onBlur={handleBlur(setYears)}
          onChange={(e) => {
            setYears(parseInt(e.target.value));
          }}
          placeholder="0"
          required
        />
      </div>
      <div className="input-group me-2">
        <span className="input-group-text">M</span>
        <input
          type="number"
          id="monthsInput"
          className="form-control"
         
          onFocus={handleFocus(setMonths)}
          onBlur={handleBlur(setMonths)}
          onChange={(e) => {
            setMonths(parseInt(e.target.value));
          }}
          placeholder="0"
          required
        />
      </div>
      <div className="input-group">
        <span className="input-group-text">D</span>
        <input
          type="number"
          id="daysInput"
          className="form-control"
          
          onFocus={handleFocus(setDays)}
          onBlur={handleBlur(setDays)}
          onChange={(e) => {
            setDays(parseInt(e.target.value));
          }}
          placeholder="0"
          required
        />
      </div>
    </div>
  </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Gender:</label>
              <select className="form-select" value={editedGender} onChange={(e)=>{setEditedGender(e.target.value)}}>
              <option value="">Select Gender...</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Other</option>
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label">Doctor Name:</label>
              <input type="hidden" className="form-control" value={editedDoctorName} onChange={(e)=>{setEditedDoctorName(e.target.value)}} />
            </div>
            <div className="mb-3">
              <label className="form-label">Title:</label>
              <select className="form-select" value={editedTitle} onChange={(e)=>{setEditedTitle(e.target.value)}} >
              <option value="">Select Title</option>
                {possibleNameTitles.map((title) => (
                  <option key={title} value={title}>
                    {title}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label className="form-label">RefByid:</label>
              <input type="hidden" className="form-control" value={patientData.user.refbyId}  />
            </div>
            <button className='btn btn-primary' onClick={()=>{stauscheck()}} disabled={disable}>Update</button>
             <button className='bn btn-danger' onClick={()=>{cancelbooking()}} disabled={patientData.status=="Cancelled" ? true:false}>Cancel Test</button> 
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default Bookingdetails;
