import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Navbar from '../component/Navbar';
import './AdminActions.css'; // Assuming this is where your CSS is stored
function Adminactions() {
  const [adminActions, setAdminActions] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Function to convert UTC to IST
  function formatToIST(dateString) {
    const options = {
      timeZone: 'Asia/Kolkata',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    };

    return new Date(dateString).toLocaleString('en-US', options);
  }


  // Fetch admin actions based on date range
  const fetchAdminActions = async () => {
    try {
      const response = await axios.post('https://apiv2.labridge.in/admin/getadminactionsbydate', {
        startDate,
        endDate,
      });
      setAdminActions(response.data);
    } catch (error) {
      console.error('Error fetching admin actions:', error);
    }
  };

  useEffect(() => {
    // Fetch all actions initially
    fetchAdminActions();
  }, []);

  return (
    <div className="wrapper">
    {/* Sidebar */}
    <Navbar />

    {/* Page Content */}
    <div id="content">
      <h2>Admin Actions</h2>

      {/* Date Filter Section */}
      <div className="filter-section">
        <div>
          <label>Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>

        <div>
          <label>End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>

        <div className="filter-buttons">
          <button onClick={fetchAdminActions}>Filter Actions</button>
          <button onClick={() => { setStartDate(''); setEndDate(''); fetchAdminActions(); }}>
            Reset Filter
          </button>
        </div>
      </div>

      {/* Admin Actions Table */}
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Lab Name</th>
              <th>Action</th>
              <th>Credit Reason</th>
              <th>Amount</th>
              <th>Timestamp (IST)</th>
            </tr>
          </thead>
          <tbody>
            {adminActions.map((action) => (
              <tr key={action._id}>
                <td>{action.details.labName || 'N/A'}</td> {/* Display lab name or fallback */}
                <td>{action.action}</td>
                <td>{action.details.reason || 'N/A'}</td> {/* Display reason or fallback */}
                <td>
                  {action.details.amount 
                    ? `₹ ${action.details.amount.toLocaleString('en-IN')}` 
                    : 'N/A'} {/* Format amount in INR, add fallback */}
                </td>
                <td>{formatToIST(action.timestamp)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
}

export default Adminactions;
