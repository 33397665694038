// Import necessary libraries
import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

// Styled components for styling
const DashboardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
`;

const StatisticCard = styled(Card)`
  flex: 1;
  min-width: 200px;
`;

// Dashboard component
const Dashboard = ({totalbooking,totalSampleCount,totalrevenue}) => {
  return (
    <DashboardContainer>
      {/* Total Booking */}
      <StatisticCard>
        <CardContent>
          <Typography variant="h5" component="div">
            Total Booking
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {totalbooking}
          </Typography>
        </CardContent>
      </StatisticCard>

      {/* Total Revenue */}
      <StatisticCard>
        <CardContent>
          <Typography variant="h5" component="div">
            Total Revenue
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {totalrevenue}
          </Typography>
        </CardContent>
      </StatisticCard>

      {/* Total Samples */}
      <StatisticCard>
        <CardContent>
          <Typography variant="h5" component="div">
            Total Samples
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {totalSampleCount}
          </Typography>
        </CardContent>
      </StatisticCard>
    </DashboardContainer>
  );
};

export default Dashboard;
