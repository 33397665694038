import axios from 'axios';
import React, { useEffect, useState } from 'react';

const SearchTest = ({ onSelectLab, labcode }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [labSuggestions, setLabSuggestions] = useState([]);

  useEffect(() => {
    const fetchLabSuggestions = async () => {
      try {
        const response = await axios.get('https://apiv2.labridge.in/admin/testdetails', {
            params:{
              term: searchTerm,
              lab :labcode._id
            },
          });
    console.log(response.data);
        setLabSuggestions(response.data.testData);

      } catch (error) {
        console.error('Error fetching lab suggestions:', error);
      }
    };

    console.log(labSuggestions);

    if (searchTerm.trim() !== '') {
      fetchLabSuggestions();
    } else {
      setLabSuggestions([]);
    }
  }, [searchTerm]);

  const handleSelectLab = (lab) => {
    setSearchTerm('');
    setLabSuggestions([]);
    onSelectLab(lab);
  };

  return (
    <div className="search-container me-2">
      <input
        type="text"
        className='input-search'
        placeholder="Search for Tests..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <ul className="suggestions-list">
        {labSuggestions.map((lab) => (
          <li key={lab._id} onClick={() => { handleSelectLab(lab); setSearchTerm(lab.testName); }}>
            {lab.testName}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchTest;
