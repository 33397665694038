
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Navbar from '../component/Navbar';

const CancelTest = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [patientData, setPatientData] = useState(null);
  const [editedPatientName, setEditedPatientName] = useState('');
  const [editedGender, setEditedGender] = useState('');
  const [editedDoctorName, setEditedDoctorName] = useState('');
  const [editedTitle, setEditedTitle] = useState('');
 const [disable, setdisable] = useState(false);
  const [years, setYears] = useState(0);
  const [months, setMonths] = useState(0);
  const [days, setDays] = useState(0);
  const handleSearch = async () => {
    try {
      const response = await axios.get(`https://apiv2.labridge.in/admin/search/${searchTerm}`);
      setPatientData(response.data);
      setEditedPatientName(response.data.patientName);
      setEditedGender(response.data.gender);
      setEditedDoctorName(response.data.doctorName);
      setEditedTitle(response.data.title);

    } catch (error) {
      console.error('Error fetching patient data:', error);
    }
  };

async function finalupdate(){
    setdisable(true);
    const result = await axios.post('https://apiv2.labridge.in/admin/updatebookingdetails',{
        name: editedPatientName,
        gender: editedGender,
        day:days,
        month:months,
        year:years,
        docter:editedDoctorName,
        title:editedTitle,
        integraionid:patientData.integrationBookingId,
        RefByID:patientData.user.refbyId,
        entityid:patientData.user.labids[0].entityid,
        id:patientData._id
    });
   if(result.data.status){
    alert("Order Updated");
    setdisable(false);
   }
   else{
    alert("Something went wrong");
    setdisable(false);
   } 
}

  const handleFocus = (setter) => () => {
    if (setter() === 0) {
      setter(null);
    }
  };
  
  const handleBlur = (setter) => (e) => {
    const value = parseInt(e.target.value);
    if (isNaN(value)) {
      setter(0);
    } else {
      setter(value);
    }
  };
  useEffect(() => {
    if (searchTerm.trim() !== '') {
      handleSearch();
    } else {
      setPatientData(null);
    }
  }, [searchTerm]);

  return (
    
    <div className="wrapper">
        <Navbar />
    <div className="container mt-5" id='content'>
      <h1 className="mb-4">Booking Details</h1>
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Enter Order Id"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {patientData && (
        <div className="card">
          <div className="card-body">
            <h2 className="card-title">Patient Information</h2>

            <div className="mb-3">
              <label className="form-label">Patient Name:</label>
              <input type="text" className="form-control" value={editedPatientName} onChange={(e)=>{setEditedPatientName(e.target.value)}} />
            </div>

            <div className="mb-3">
            <div className="form-outline">
    <label className="form-label" htmlFor="ageInput">
      Age(current age - {patientData.age}) 
    </label>
    <div className="d-flex">
      <div className="input-group me-2">
        <span className="input-group-text">Y</span>
        <input
          type="number"
          id="yearsInput"
          className="form-control"
          
          onFocus={handleFocus(setYears)}
          onBlur={handleBlur(setYears)}
          onChange={(e) => {
            setYears(parseInt(e.target.value));
          }}
          placeholder="0"
          required
        />
      </div>
      <div className="input-group me-2">
        <span className="input-group-text">M</span>
        <input
          type="number"
          id="monthsInput"
          className="form-control"
         
          onFocus={handleFocus(setMonths)}
          onBlur={handleBlur(setMonths)}
          onChange={(e) => {
            setMonths(parseInt(e.target.value));
          }}
          placeholder="0"
          required
        />
      </div>
      <div className="input-group">
        <span className="input-group-text">D</span>
        <input
          type="number"
          id="daysInput"
          className="form-control"
          
          onFocus={handleFocus(setDays)}
          onBlur={handleBlur(setDays)}
          onChange={(e) => {
            setDays(parseInt(e.target.value));
          }}
          placeholder="0"
          required
        />
      </div>
    </div>
  </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Gender:</label>
              <input type="text" className="form-control" value={editedGender} onChange={(e)=>{setEditedGender(e.target.value)}} />
            </div>

            <div className="mb-3">
              <label className="form-label">Doctor Name:</label>
              <input type="text" className="form-control" value={editedDoctorName} onChange={(e)=>{setEditedDoctorName(e.target.value)}} />
            </div>
            <div className="mb-3">
              <label className="form-label">Title:</label>
              <input type="text" className="form-control" value={editedTitle} onChange={(e)=>{setEditedTitle(e.target.value)}} />
            </div>
            <div className="mb-3">
              <label className="form-label">RefByid:</label>
              <input type="text" className="form-control" value={patientData.user.refbyId} />
            </div>
            <button className='bn btn-primary' onClick={()=>{finalupdate()}} disabled={disable}>Update</button>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default CancelTest;
