import axios from 'axios';
import React, { useEffect, useState } from 'react';

const SearchClient = ({ onSelectLab, slabid }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [labSuggestions, setLabSuggestions] = useState([]);
console.log("idss", slabid);
  useEffect(() => {
    const fetchLabSuggestions = async () => {
      try {
        const response = await axios.get(`https://apiv2.labridge.in/admin/searchs?term=${searchTerm}&labids=${slabid}`);
        setLabSuggestions(response.data);
      } catch (error) {
        console.error('Error fetching lab suggestions:', error);
      }
    };

    if (searchTerm.trim() !== '') {
      fetchLabSuggestions();
    } else {
      setLabSuggestions([]);
    }
  }, [searchTerm]);

  const handleSelectLab = (lab) => {
    setSearchTerm('');
    setLabSuggestions([]);
    onSelectLab(lab);
  };

  return (
    <div className="search-container me-2">
      <input
        type="text"
        className='input-search'
        placeholder="Search for labs..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <ul className="suggestions-list">
        {labSuggestions.map((lab) => (
          <li key={lab._id} onClick={() => { handleSelectLab(lab); setSearchTerm(lab.labName); }}>
            {lab.labName}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchClient;
